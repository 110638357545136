<template>
  <div class="user-info">
    <div class="vip-status">
      <div v-if="userInfo.vip_level > 0" class="vip-remain">
        <span class="label">会员有效期：</span>
        <span class="remain-time">{{
          formatTime(userInfo.vip_expire_time * 1000)
        }}</span>
      </div>
      <div class="vip-remain">
        <span class="label">{{
          userVip ? userVip.levelname : "普通会员"
        }}</span>
      </div>
      <span class="recharge-btn" @click="handleRecharge">{{
        userInfo.vip_level > 0 ? "续费会员" : "升级会员"
      }}</span>
    </div>
    <div class="split-line" />
    <div class="user-info-container">
      <img class="avatar" :src="userInfo.avatar || userInfo.avatarImg" />
      <div class="user-info-content">
        <div>
          <span class="label-name">姓名：</span>
          <span class="name">{{ userInfo.real_name }}</span>
        </div>
        <div>
          <span class="label-mobile">手机号码：</span>
          <span class="mobile">{{ userInfo.mobile }}</span>
        </div>
      </div>
      <span class="sex-label">性别：</span>
      <span class="sex">{{ userInfo.sex === 1 ? "男" : "女" }}</span>
      <span class="change-profile-btn" @click="handleChangeProfile"
        >修改资料</span
      >
    </div>
    <pay-dialog ref="pay-dialog" @success="fetchUserInfo" />
    <profile ref="profile" @success="fetchUserInfo" />
    <div class="qr-code">
      <div>用户推广二维码</div>
      <img :src="userInfo.qrcode" alt="" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
// import apis from "@/apis/index";
import dayjs from "dayjs";
import PayDialog from "@/components/web/PayDialog";
import Profile from "@/components/web/Profile.vue";

export default {
  name: "Center",
  components: {
    PayDialog,
    Profile
  },
  computed: {
    ...mapState(["userInfo", "vipIndex"]),
    userVip() {
      return (
        this.vipIndex.find(item => item.id === this.userInfo.vip_level) || null
      );
    }
  },
  mounted() {
    // this.fetchRechargeIndex();
  },
  methods: {
    ...mapActions(["fetchUserInfo"]),
    // async fetchRechargeIndex() {
    //   const { data } = await apis.rechargeIndex();
    //   console.log(data);
    // },
    async handleRecharge() {
      // const { data } = await apis.recharge({ goods_id: 1 });
      // console.log(data);
      this.$refs["pay-dialog"].show();
    },
    handleChangeProfile() {
      this.$refs["profile"].show();
    },
    formatTime(time, format = "YYYY-MM-DD") {
      return dayjs(time).format(format);
    }
  }
};
</script>

<style></style>

<style lang="scss" scoped>
.user-info {
  margin: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  background-color: #f7f7f7;
  width: 400px;
  height: 120px;
  overflow: hidden;
}

.vip-status {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
  width: 360px;
  height: 16px;
}

.vip-remain {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 14px;
}

.label {
  margin-right: 9px;
  max-width: 144px;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  line-height: 14px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #666666;
  font-size: 14px;
}

.remain-time {
  margin-top: -1px;
  max-width: 142px;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  line-height: 14px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #CA0A15;
  font-size: 14px;
}

.recharge-btn {
  // width: 29px;
  height: 16px;
  font-size: 14px;
  color: #148bfa;
  line-height: 16px;
  webkit-line-clamp: 2px;
  overflow: hidden;
  text-decoration: underline;
  text-overflow: ellipsis;
  letter-spacing: 0px;
  cursor: pointer;
}

.change-profile-btn {
  // width: 29px;
  margin-left: 10px;
  height: 16px;
  font-size: 14px;
  color: #148bfa;
  line-height: 18px;
  webkit-line-clamp: 2px;
  overflow: hidden;
  text-decoration: underline;
  text-overflow: ellipsis;
  letter-spacing: 0px;
  cursor: pointer;
}

.split-line {
  position: relative;
  margin-top: 12px;
  background-color: #efefef;
  width: 400px;
  height: 1px;
  overflow: hidden;
}

.user-info-container {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 15px;
  height: 40px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.avatar {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.user-info-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // justify-content: space-between;
  flex-wrap: wrap;
  // margin-right: 131px;
  // width: 122px;
  // height: 40px;
  position: relative;
  flex: 1;
}

.label-name {
  margin-right: 8px;
  margin-bottom: 10px;
  text-decoration: none;
  line-height: 12px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #666666;
  font-size: 12px;
}

.name {
  margin-bottom: 10px;
  text-decoration: none;
  line-height: 12px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #CA0A15;
  font-size: 12px;
}

.label-mobile {
  margin-right: 8px;
  text-decoration: none;
  line-height: 12px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #666666;
  font-size: 12px;
}

.mobile {
  text-decoration: none;
  line-height: 12px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #CA0A15;
  font-size: 12px;
}

.sex-label {
  margin-top: 4px;
  margin-right: 8px;
  text-decoration: none;
  line-height: 12px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #666666;
  font-size: 12px;
}

.sex {
  margin-top: 5px;
  text-decoration: none;
  line-height: 12px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #148bfa;
  font-size: 12px;
}

.qr-code {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
  text-align: center;

  div {
    margin-bottom: 10px;
  }

  img {
    height: 200px;
    width: 200px;
  }
}
</style>
