<template>
  <el-dialog
    :visible.sync="visible"
    :show-close="false"
    width="500px"
    custom-class="modal-container"
  >
    <div slot="title" class="title">
      <span class="info">
        修改资料
      </span>
      <img
        alt="icon"
        class="icon"
        src="@/assets/images/login-dialog/img_64028_0_0.png"
        @click="visible = false"
      />
    </div>
    <div class="login-modal">
      <div class="content">
        <div
          style="display:flex; align-items: center; height: 100px; margin-left: 20px;"
        >
          <span>修改头像：</span>
          <el-upload
            class="avatar-uploader"
            :action="uploadAPi"
            :data="uploadData"
            :show-file-list="false"
            name="avatar"
            :on-success="handleAvatarSuccess"
          >
            <img
              v-if="profileForm.avatar"
              :src="fillSrc(profileForm.avatar)"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <input
          v-model="profileForm.mobile"
          class="text-input"
          placeholder="请输入手机号"
        />
        <div v-if="showSms" class="input-wrapper">
          <input
            v-model="profileForm.sms_code"
            class="text-input"
            placeholder="请输入验证码"
          />
          <el-button
            class="get-verify-code"
            :disabled="smsLoading"
            @click="sendSms()"
            >{{ smsLoading ? smsCount : "获取验证码" }}</el-button
          >
        </div>
        <div class="input-wrapper">
          <input
            v-model="profileForm.real_name"
            class="text-input"
            placeholder="请输入姓名"
          />
          <el-select
            v-model="profileForm.sex"
            class="select-sex"
            placeholder="请选择"
          >
            <el-option label="男" :value="1"></el-option>
            <el-option label="女" :value="2"></el-option>
          </el-select>
        </div>
        <div class="ft" @click="submit">
          修改
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Dialog, Button, Select, Option, Upload } from "element-ui";
import apis from "@/apis/index";
import { apiRoot } from "@/config";

function pick(obj, props) {
  // Create new object
  var picked = {};

  // Loop through props and push to new object
  for (let prop of props) {
    picked[prop] = obj[prop];
  }

  // Return new object
  return picked;
}

export default {
  name: "LoginDialog",
  components: {
    "el-dialog": Dialog,
    "el-button": Button,
    "el-select": Select,
    "el-option": Option,
    "el-upload": Upload
  },
  data() {
    return {
      uploadAPi: `${apiRoot}/user/upload_avatar`,
      visible: false,
      current: "login",
      smsLoading: false,
      smsCount: 60,
      destTimestamp: 0,
      profileForm: {
        mobile: "",
        sms_code: "",
        avatar: "",
        sex: "",
        real_name: ""
      }
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    showSms() {
      return this.profileForm.mobile !== this.userInfo.mobile;
    },
    uploadData() {
      const auth = localStorage.getItem("auth") || "{}";
      const { token } = JSON.parse(auth);
      return { token };
    }
  },
  methods: {
    ...mapActions(["fetchUserInfo"]),
    fillSrc(src) {
      if (src.indexOf("http") >= 0) return src;
      return `${apiRoot}${src}`;
    },

    show() {
      this.profileForm = pick(this.userInfo, Object.keys(this.profileForm));
      this.visible = true;
    },

    handleAvatarSuccess(res) {
      const {
        data: { avatar_url }
      } = res;
      this.profileForm.avatar = avatar_url;
    },

    async sendSms() {
      if (this.smsLoading) return;
      let mobile = "";
      if (!this.profileForm.mobile.trim()) return;
      mobile = this.profileForm.mobile.trim();

      await apis.smsCode({ mobile: parseInt(mobile) });
      this.$message.success("验证码发送成功");
      this.smsLoading = true;
      this.destTimestamp = Date.now() + 1000 * 60;
      this.smsCounter = setInterval(() => {
        if (Date.now() >= this.destTimestamp) {
          this.smsCounter && clearInterval(this.smsCounter);
          this.smsLoading = false;
          this.smsCount = 60;
          return;
        }
        this.smsCount = Math.round((this.destTimestamp - Date.now()) / 1000);
      }, 1000);
    },

    async submit() {
      await apis.updateProfile({
        ...this.profileForm
      });
      this.fetchUserInfo();
      this.$message.success("修改成功");
      this.visible = false;
    }
  }
};
</script>

<style lang="scss">
.modal-container {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.avatar-uploader {
  height: 100px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border-right: 50%;
  overflow: hidden;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}
.title {
  align-items: center;
  background-color: #fbe1c3;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: space-between;
  overflow: hidden;
  padding-left: 21px;
  padding-right: 20px;
  position: relative;
  width: 500px;

  .info {
    color: #CA0A15;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
    font-weight: bold;
  }
  .icon {
    height: 10px;
    width: 10px;
    cursor: pointer;
  }
}
.login-modal {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 500px;

  .tabs {
    align-items: center;
    align-self: center;
    display: flex;
    flex-direction: row;
    height: 20px;
    justify-content: space-between;
    margin-top: 30px;
    position: relative;
    width: 183px;
  }
  .tab {
    color: #8c6b64;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    text-decoration: none;
    cursor: pointer;

    &.active {
      color: #CA0A15;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .underline {
    background-color: #CA0A15;
    height: 4px;
    margin-top: 14px;
    overflow: hidden;
    position: relative;
    width: 20px;
    transition: margin-left 0.3s ease;

    &.left {
      margin-left: 168px;
    }

    &.right {
      margin-left: 310px;
    }
  }
  .content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-bottom: 32px;
    width: 100%;
  }
  .text-input {
    align-items: center;
    align-self: center;
    border-color: #efefef;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: 40px;
    margin-top: 30px;
    overflow: hidden;
    position: relative;
    width: 460px;
    color: #666666;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
    text-decoration: none;
    padding: 14px 20px;
    transition: border-color 0.3s ease;

    &:focus {
      border-color: #CA0A15;
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
    }
  }
  .input-wrapper {
    position: relative;
    width: 460px;
    align-self: center;

    .get-verify-code {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      border-radius: 4px;
      background-color: #CA0A15;
      padding-right: 20px;
      padding-left: 20px;
      height: 30px;
      overflow: hidden;
      width: 100px;
      color: #ffffff;
      font-size: 12px;
      position: absolute;
      right: 5px;
      bottom: 5px;
      // cursor: pointer;

      &:disabled {
        opacity: 0.7;
      }
    }

    .select-sex {
      height: 40px;
      width: 100px;
      font-size: 12px;
      position: absolute;
      right: 0px;
      bottom: 0px;
    }
  }
  .forget-password {
    -webkit-line-clamp: 2;
    align-self: flex-end;
    color: #0ab0fb;
    font-size: 12px;
    height: 13px;
    letter-spacing: 0;
    line-height: 13px;
    margin-right: 20px;
    margin-top: 14px;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    text-overflow: ellipsis;
    width: 64px;
    cursor: pointer;
  }
  .ft {
    align-items: center;
    align-self: center;
    background-color: #CA0A15;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: 40px;
    margin-top: 13px;
    overflow: hidden;
    padding-left: 85px;
    padding-right: 83px;
    position: relative;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
    cursor: pointer;
  }
}
</style>
